<template>
  <div>
    <a-carousel>
      <div>
        <img
          src="@/assets/aboutUs/cooperation_banner-1.png"
          style="-webkit-user-drag: none"
          alt=""
        />
      </div>
    </a-carousel>

    <div class="content">
      <a-card :bordered="false">
        <template slot="title">
          <h2>{{ $t('message')['home.cooperationScheme'] }}</h2>
        </template>

        <div v-for="item in cooperation" :key="item.title">
          <a-row>
            <a-col :span="5" :offset="1">
              <img style="width: 100%" alt="cooperation" :src="item.img" />
            </a-col>
            <a-col :span="17" :offset="1">
              <p style="font-weight: bold; font-size: large; color: #333333">
                {{ item.title }}
              </p>

              <p class="content-text">{{ item.content }}</p>
            </a-col>
          </a-row>

          <a-divider style="margin-top: 20px"></a-divider>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'Cooperation',
  data() {
    return {
      cooperation: dataZH.cooperation,
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
  },
  methods: {
    // 更改语言
    changeLanguage(language) {
      this.cooperation =
        language === 'enUS' ? dataEN.cooperation : dataZH.cooperation
    },
  },
  watch: {
    '$i18n.locale': function (val) {
      this.changeLanguage(val)
    },
  },
}
</script>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 250px;
  line-height: 250px;
  background: #364d79;
  overflow: hidden;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}
</style>
