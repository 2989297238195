<template>
  <div>
    <a-carousel>
      <div>
        <img
          src="@/assets/aboutUs/banner-1.png"
          style="-webkit-user-drag: none"
          alt=""
        />
      </div>
    </a-carousel>

    <div class="content">
      <a-card :bordered="false">
        <template slot="title">
          <h2>{{ $t('message')['home.companyIntroduce'] }}</h2>
        </template>

        <a-row v-if="company && company.introduce">
          <a-col :span="24">
            <p
              class="content-text"
              v-for="item in company.introduce"
              :key="item"
            >
              {{ item }}
            </p>

            <p
              class="content-text"
              style="font-weight: bold; font-size: larger"
            >
              {{ $t('message')['home.productFeature'] }}
            </p>

            <p class="content-text" v-for="item in company.feature" :key="item">
              {{ item }}
            </p>
          </a-col>
        </a-row>
      </a-card>

      <a-card :bordered="false" style="padding-top: 20px">
        <template slot="title">
          <h2>{{ $t('message')['aboutUs.companyCulture'] }}</h2>
        </template>

        <a-row v-if="company && company.culture">
          <a-col :span="24">
            <p class="content-text" v-for="item in company.culture" :key="item">
              {{ item }}
            </p>
          </a-col>
        </a-row>
      </a-card>

      <a-card :bordered="false" style="padding-top: 20px">
        <template slot="title">
          <h2>{{ $t('message')['aboutUs.contactUs'] }}</h2>
        </template>

        <a-row v-if="company && company.culture">
          <a-col :span="12" :offset="1">
            <p style="color: #4d4d4d; font-size: 16px">
              <span style="font-weight: bolder">
                {{ $t('message')['aboutUs.phone'] }}：
              </span>
              {{ baseInfo.phone }}
            </p>
            <p style="color: #4d4d4d; font-size: 16px">
              <span style="font-weight: bolder">QQ：</span>
              {{ baseInfo.qq }}
            </p>
            <p style="color: #4d4d4d; font-size: 16px">
              <span style="font-weight: bolder">
                {{ $t('message')['aboutUs.email'] }}：
              </span>
              {{ baseInfo.email }}
            </p>
            <p style="color: #4d4d4d; font-size: 16px">
              <span style="font-weight: bolder">
                {{ $t('message')['aboutUs.address'] }}：
              </span>
              {{ baseInfo.address }}
            </p>
          </a-col>

          <a-col :span="4" :offset="7" style="text-align: center">
            <img
              style="width: 150px; height: 150px"
              alt="company_contact"
              src="@/assets/shop-qr.png"
            />
            <p style="color: #4d4d4d; font-size: 12px">
              扫描二维码进入宏佳电子淘宝店
            </p>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'AboutUs',
  data() {
    return {
      company: dataZH.company,
      baseInfo: dataZH.baseInfo,
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
  },
  methods: {
    // 更改语言
    changeLanguage(language) {
      this.company = language === 'enUS' ? dataEN.company : dataZH.company
      this.baseInfo = language === 'enUS' ? dataEN.baseInfo : dataZH.baseInfo
    },
  },
  watch: {
    '$i18n.locale': function (val) {
      this.changeLanguage(val)
    },
  },
}
</script>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 250px;
  line-height: 250px;
  background: #364d79;
  overflow: hidden;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}
</style>
